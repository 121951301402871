body {
  font-family: "Tajawal", sans-serif !important;
  text-align: right;
  direction: rtl;
}

.customH1 {
  font-size: 2em;
}

@media (min-width: 576px) {
  .customH1 {
    font-size: xx-large;
  }
}

@media (min-width: 768px) {
  .customH1 {
    font-size: xxx-large;
  }
}


.ellipsis-text {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}


.rtl {
  text-align: right !important;
  direction: rtl !important;
}

.ltr {
  text-align: left !important;
  direction: ltr !important;
}

img {
  max-height: 240px;
  margin: 0 auto;
}

.app-container {
  width: 500px;
  margin: 30px auto;
}

.clear {
  clear: both;
}

.slick-slide {
  padding: 0%;
}

.details,
.title {
  text-align: center;
}

.error {
  margin-top: 4px;
  color: red;
}

.has-error {
  border: 1px dotted red;
}

.image-container {
  align-items: center;
  display: flex;
  width: 85%;
  height: 80%;
  float: left;
  margin: 15px 10px 10px 37px;
  text-align: center;
}

.preview-container {
  height: 335px;
  width: 100%;
  margin-bottom: 10px;
}

.placeholder-preview,
.render-preview {
  text-align: center;
  background-color: #efebeb;
  height: 100%;
  width: 100%;
  border-radius: 5px;
}

.upload-container {
  cursor: pointer;
  height: 300px;
}

.wizard-alternate-2 .steps-indicator li:last-child {
  padding-left: 0 !important;
}

.wizard-alternate-2 .steps-indicator li {
  padding: 0.5rem 0 0 0.66667rem !important;
}

@media screen and (max-width: 1380.98px) {
  .wizard-alternate-2 .steps-indicator li:last-child {
    padding-left: 0 !important;
  }

  .wizard-alternate-2 .steps-indicator li {
    padding: 0.5rem 0 0 0 !important;
  }
}